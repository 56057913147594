import {
    CBadge,
    CButton,
    CCol,
    CContainer,
    CRow,
    CTableDataCell,
    CTableRow
} from '@coreui/react'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import * as icon from '@coreui/icons';
import CIcon from '@coreui/icons-react';


const SubscriptionListComp = ({
    title,
    id,
    duration,
    period,
    sell_price,
    isActive,
    regular_price,
    discount
}) => {
    const navigate = useNavigate()
    return (
        <CTableRow>
            <CTableDataCell>{title}</CTableDataCell>
            <CTableDataCell>{duration<=1?`${duration} month`:`${duration} months`}</CTableDataCell>
            <CTableDataCell>{regular_price}</CTableDataCell>
            <CTableDataCell>{sell_price}</CTableDataCell>
            <CTableDataCell>{discount} %</CTableDataCell>
            <CTableDataCell>{
                isActive ? <CBadge className='bg-success'>Active</CBadge> : <CBadge className='bg-danger'>inActive</CBadge>
            }</CTableDataCell>
            <CTableDataCell> {
                title !== "Basic Membership" &&< CIcon onClick = {
                    () => navigate(`/subscriptionplans/${id}`)
                }
                icon = {
                    icon.cilPencil
                } />
            } </CTableDataCell>
        </CTableRow>
    )
}

export default SubscriptionListComp

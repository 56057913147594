import React, { useEffect, useState } from 'react'
import { PostCommentsAdmin, PostCommentReply } from "../Helper/Index.Api"
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri"
import strings from '../strings';
import { CSpinner } from '@coreui/react';

const PostComments = ({ id }) => {
    const [comments, setComments] = useState([]);
    const [commentId, setCommentId] = useState("");
    const [reply, setReply] = useState([]);
    const [showcomment, setShowcomment] = useState("");
    const [loading, setLoading] = useState(false);


    // get post comments
    useEffect(() => {
        setLoading(true)
        PostCommentsAdmin(id).then((res) => {
            setComments(res.data)
            setLoading(false)
        }).catch((err) => { setLoading(false); console.log(err) })
    }, [])

    // show reply
    const handleClick = (id) => {
        setReply([])
        if (id === commentId) {
            setCommentId("")
        } else {
            setCommentId(id)
        }
    }

    // show comments
    const handleShowComment = (id) => {
        if (id === showcomment) {
            setShowcomment("")
        } else {
            setShowcomment(id)
        }
    }


    return (
        <>
            {loading ? <div className='text-center mt-5'><CSpinner color='info' /></div> : (<div style={{ height: "55vh", overflow: "auto" }} className='px-2'> {
                comments?.length > 0 && <div> {
                    comments?.map((el) => {
                        return <>
                            <div style={
                                { display: "flex", marginBottom: "20px" }
                            }
                                className='gap-1'>
                                <img className=''
                                    style={
                                        {
                                            width: "40px",
                                            height: "40px",
                                            borderRadius: "50%"
                                        }
                                    }
                                    src={
                                        el?.createdBy?.userAvatar?.cdnlocation ? strings.MEDIAPATH + el?.createdBy?.userAvatar?.cdnlocation : "https://th.bing.com/th/id/OIP.Gfp0lwE6h7139625a-r3aAHaHa?w=194&h=194&c=7&r=0&o=5&dpr=1.3&pid=1.7"
                                    }
                                    alt="image" />
                                <div style={{ width: "100%" }}>
                                    <p style={{ fontSize: "14px", marginBottom: "2px" }}>{el?.createdBy?.first_Name + " " + el.createdBy?.last_Name}</p>
                                    <div style={
                                        {
                                            display: "inline-block",
                                            backgroundColor: "rgba(4, 117, 107, 0.1)",
                                            color: "#000000",
                                            wordWrap: "break-word",
                                            position: "relative",
                                            // marginBottom: "20px",
                                            width: "100%"
                                        }
                                    }
                                        className='px-4 rounded-2'>
                                        <p style={
                                            {
                                                fontSize: "16px",
                                                marginTop: "10px"
                                            }
                                        }>
                                            {
                                                el?.comment
                                            }</p>
                                        <div style={
                                            {
                                                fontSize: "12px",
                                                position: "absolute",
                                                bottom: 4,
                                                right: 10
                                            }
                                        }>
                                            <span>likes {
                                                el?.commentLikes
                                            }</span>
                                            <span style={
                                                { marginLeft: "5px" }
                                            }
                                                onClick={
                                                    () => handleClick(el._id)
                                                }>Reply {
                                                    el.replyCount
                                                }</span>
                                            {
                                                el?.comment?.length > 100 && <span style={
                                                    { marginLeft: "5px" }
                                                }
                                                    onClick={
                                                        () => handleShowComment(el?._id)
                                                    }>
                                                    {
                                                        el._id !== showcomment && <RiArrowDropDownLine fontSize={"16px"} />
                                                    }
                                                    {
                                                        el._id === showcomment && <RiArrowDropUpLine fontSize={"16px"} />
                                                    }</span>
                                            } </div>
                                        <div style={
                                            {
                                                fontSize: "12px",
                                                position: "absolute",
                                                top: 3,
                                                right: 6
                                            }
                                        }>
                                            {
                                                el?.createdAt
                                            } </div>
                                    </div>
                                </div>
                            </div>
                            {
                                <div style={
                                    {
                                        display: "flex",
                                        justifyContent: "end",
                                        flexDirection: "column",
                                        marginLeft: "2em"
                                    }
                                }>
                                    {
                                        el?.reply?.map((el) => {
                                            return <div style={
                                                { display: "flex", marginBottom: "20px", }
                                            }
                                                className='gap-1'>
                                                <img className=''
                                                    style={
                                                        {
                                                            width: "40px",
                                                            height: "40px",
                                                            borderRadius: "50%"
                                                        }
                                                    }
                                                    src={
                                                        el?.createdBy?.userAvatar?.cdnlocation ? strings.MEDIAPATH + el?.createdBy?.userAvatar?.cdnlocation : "https://th.bing.com/th/id/OIP.Gfp0lwE6h7139625a-r3aAHaHa?w=194&h=194&c=7&r=0&o=5&dpr=1.3&pid=1.7"
                                                    }
                                                    alt="image" />
                                                <div style={{ width: "100%" }}>
                                                    <p style={{ fontSize: "14px", marginBottom: "2px" }}>{el?.createdBy?.first_Name + " " + el.createdBy?.last_Name}</p>
                                                    <div style={
                                                        {
                                                            display: "inline-block",
                                                            backgroundColor: "#f3f4f8",
                                                            color: "#000000",
                                                            wordWrap: "break-word",
                                                            position: "relative",
                                                            marginBottom: "10px",
                                                            width: "100%"
                                                        }
                                                    }
                                                        className='px-4 py-1 rounded-2'>

                                                        {
                                                            showcomment !== el._id && <p style={
                                                                {
                                                                    fontSize: "16px",
                                                                    marginTop: "10px"
                                                                }
                                                            }>
                                                                {

                                                                    el?.reply
                                                                }</p>
                                                        }
                                                        {
                                                            showcomment === el._id && showcomment !== "" && <p style={
                                                                {
                                                                    fontSize: "16px",
                                                                    marginTop: "10px"
                                                                }
                                                            }>
                                                                {

                                                                    el?.reply
                                                                }</p>
                                                        }
                                                        <div style={
                                                            {
                                                                fontSize: "12px",
                                                                position: "absolute",
                                                                bottom: 4,
                                                                right: 10
                                                            }
                                                        }>
                                                            <span>likes {
                                                                el.likeCount
                                                            }</span></div>
                                                        <div style={
                                                            {
                                                                fontSize: "12px",
                                                                position: "absolute",
                                                                top: 3,
                                                                right: 6

                                                            }
                                                        }>
                                                            {
                                                                el?.createdAt
                                                            } </div>
                                                        <div style={
                                                            {
                                                                fontSize: "10px",
                                                                position: "absolute",
                                                                bottom: 4,
                                                                right: 10
                                                            }
                                                        }>
                                                            {
                                                                el?.reply?.length > 100 && el?._id !== showcomment && < RiArrowDropDownLine fontSize={
                                                                    "16px"
                                                                }
                                                                    onClick={
                                                                        () => {
                                                                            handleShowComment(el?._id)
                                                                        }
                                                                    } />
                                                            }
                                                            {
                                                                el?.reply?.length > 100 && el?._id === showcomment && < RiArrowDropUpLine fontSize={
                                                                    "16px"
                                                                }
                                                                    onClick={
                                                                        () => {
                                                                            handleShowComment(el?._id)
                                                                        }
                                                                    } />
                                                            } </div>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }
                                    {
                                        loading && <div>
                                            <CSpinner color="info" />
                                        </div>

                                    }
                                </div>
                            } </>
                    })
                } </div>
            }
                {
                    comments?.length <= 0 && <div className='d-flex justify-content-center'><p style={{ fontSize: "14px" }}>No comment found.</p></div>
                } </div>)}
        </>
    )
}

export default PostComments;

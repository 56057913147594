const strings = {
  // API_URL: process.env.REACT_APP_API_URL,
  // ADMINPROFILE: process.env.REACT_APP_ADMINPROFILE,
  // USERPROFILE: process.env.REACT_APP_USERPROFILE,
  // ADMINBADGE:process.env.REACT_APP_ADMINBADGE,
  // UPLOADS: process.env.REACT_APP_UPLOADS,

  API_URL: process.env.REACT_APP_API_URL,
  // API_URL:process.env.REACT_APP_API_URL,
  ADMINPROFILE: process.env.REACT_APP_ADMINPROFILE,
  USERPROFILE: process.env.REACT_APP_USERPROFILE,
  ADMINBADGE: process.env.REACT_APP_ADMINBADGE,
  UPLOADS: process.env.REACT_APP_UPLOADS,
  CHATMEADIA: process.env.REACT_APP_CHATMEADIA,
  MEDIAPATH: process.env.REACT_APP_MEDIAPATH
};
export default strings; 
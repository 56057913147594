import {
    CAvatar,
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CContainer,
    CFormInput,
    CFormLabel,
    CFormTextarea,
    CRow,
    CSpinner
} from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getSingleEnquiryDetails, updateEnquiry } from '../Helper/Index.Api'
import swal from 'sweetalert'
import AppTitle from '../AppTitle'
import strings from '../strings'
import toast from 'react-hot-toast'


export const EnquiriesDetails = () => {
    const navigate = useNavigate()
    const [avatar, setAvatar] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [enquiryDate, setEnquiryDate] = useState("")
    const [email, setEmail] = useState("")
    const [phonenumber, setPhoneNumber] = useState("")
    const [country, setCountry] = useState("")
    const [subject, setSubject] = useState("")
    const [message, setMessage] = useState("")
    const [reply, setReply] = useState("")
    const [replyBy, setReplyBy] = useState("")
    const { id } = useParams()
    const [feedback, setFeedback] = useState("")
    const [loading, isLoading] = useState(false);
    const [state, setState] = useState("");
    const [showError, setShowError] = useState(false);
    const [enquiryReply, setEnquiryReply] = useState(false);


    // get enqury details api
    useEffect(() => {
        isLoading(true)
        getSingleEnquiryDetails(id).then((res) => {
            setAvatar(res?.enquiry?.userId?.userAvatar?.cdnlocation)
            setFirstName(res.enquiry.userId.first_Name)
            setLastName(res.enquiry.userId.last_Name)
            setEmail(res.enquiry.userId.email)
            setPhoneNumber(res.enquiry.userId.phonenumber)
            setCountry(res.enquiry.userId.country)
            setMessage(res.enquiry.message)
            setSubject(res.enquiry.subject)
            setReply(res.enquiry.reply)
            setReplyBy(res.enquiry.reply_by.userName)
            setState(res.enquiry.userId.state)
            isLoading(false)
        }).catch(err => {
            isLoading(false)
        })

    }, [])


    // submitting reply by admin and sending email to the user who have created enquiry
    const handleSubmit = () => {
        if (feedback.length <= 0) {
            setEnquiryReply(true)
            return
        }
        if (showError) {
            return;
        }

        let payload = {
            id: id,
            reply: feedback,
            reply_by: localStorage.getItem("_id")
        }

        updateEnquiry(payload).then((res) => {
            if (res.err === 200) {
                swal("Enquiry", "Enquiry successfully added", "success").then(() => navigate("/enquiries"))
            }
        }).catch(err => console.log(err))

    }

    // handle change
    const handleChange = (e) => {
        if (e.target.value.length < 20) {
            setShowError(true)
        } else {
            setShowError(false)
        }
        setFeedback(e.target.value);
        setEnquiryReply(false);
    };


    return (
        <>
            <AppTitle title={"Enquiry Details"}
                path={"/enquiries"}
                showbtn={true} /> {
                loading ? (
                    <div className='d-flex justify-content-center'><CSpinner color='info'
                        style={
                            { marginTop: "40px" }
                        } /></div>
                ) : (
                    <div>
                        <div className='container px-lg-5 mt-lg-5 px-2 mt-2'>
                            <CRow>
                                <CCol md={12}
                                    lg={12}
                                    sm={12}>
                                    <CCard style={
                                        { borderRadius: "5px 5px 0px 0px" }
                                    }>
                                        <CRow>
                                            <CCol sm={12}>
                                                <div style={
                                                    {
                                                        backgroundColor: "rgb(79 113 189 / 39%)",
                                                        height: "150px",
                                                        borderRadius: "5px 5px 0px 0px"
                                                    }
                                                }></div>
                                                <div style={
                                                    {
                                                        position: "absolute",
                                                        zIndex: 1,
                                                        top: "75px",
                                                        left: "25px"
                                                    }
                                                }>
                                                    <img src={
                                                        avatar !== "" ? strings?.MEDIAPATH + avatar : "https://th.bing.com/th/id/OIP.Gfp0lwE6h7139625a-r3aAHaHa?w=194&h=194&c=7&r=0&o=5&dpr=1.3&pid=1.7"
                                                    }
                                                        style={
                                                            {
                                                                width: "150px",
                                                                height: "150px"
                                                            }
                                                        }
                                                        className='rounded-circle border border-white border-5'
                                                        alt="" />

                                                    <div className='ms-1'>
                                                        <h4> {
                                                            firstName?.charAt(0).toUpperCase() + firstName?.slice(1)
                                                        }
                                                            {" "}
                                                            {lastName} </h4>
                                                        <small className='pb-3'
                                                            style={
                                                                { color: "black" }
                                                            }>
                                                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                                                            {"  "}
                                                            {state}
                                                            {" "}
                                                            {country}
                                                            <br />
                                                            <i class="fa fa-envelope-o" aria-hidden="true"></i>
                                                            {" "}
                                                            {email}
                                                            <br />
                                                            <i class="fa fa-phone" aria-hidden="true"></i>
                                                            {" "}
                                                            {phonenumber} </small>
                                                    </div>
                                                </div>
                                                <div style={
                                                    {
                                                        backgroundColor: "white",
                                                        minHeight: "250px",
                                                        borderRadius: " 0px 0px 0px 0px"
                                                    }
                                                }></div>
                                                <div className='container px-md-5 px-2 mt-2 mb-4'>
                                                    <CFormLabel>Subject :</CFormLabel>
                                                    <div className='border border-1 rounded px-4 py-2'>
                                                        {subject} </div>
                                                    <br />
                                                    <CFormLabel>Message :</CFormLabel>
                                                    <div className='border border-1 rounded px-4 py-2'>
                                                        {message} </div>
                                                </div>
                                            </CCol>
                                        </CRow>
                                    </CCard>
                                    <CCard className='mt-3 px-2 px-md-5 py-2'
                                        style={
                                            { borderRadius: "5px 5px 0px 0px" }
                                        }>
                                        <h5 style={{ color: "#5470af" }}>Reply from Indians Abroad Team</h5>
                                        <CFormLabel>Reply :</CFormLabel>
                                        {
                                            reply === "" && <><CFormTextarea rows={6}
                                                placeholder='Reply'
                                                onChange={handleChange}
                                                color={`${showError ? "red" : ""}`}
                                            /> {
                                                    !enquiryReply && showError && <span style={
                                                        {
                                                            color: "red",
                                                            fontSize: "14px"
                                                        }
                                                    }>Minimum 20 characters required.</span>
                                                }
                                                {enquiryReply && <span style={{ color: "red", fontSize: "14px" }}>Please enter enquiry reply.</span>}
                                            </>
                                        }
                                        {
                                            reply === "" && <br />
                                        }
                                        {
                                            reply === "" && <CButton onClick={handleSubmit}
                                                style={
                                                    {
                                                        width: "150px",
                                                        backgroundColor: "#5470af"
                                                    }
                                                }>Submit</CButton>
                                        }
                                        {
                                            reply !== "" && <div style={
                                                {
                                                    color: "#5470af",
                                                    fontSize: "18px",
                                                    fontWeight: 600
                                                }
                                            }
                                                className='border border-1 rounded px-4 py-2'>
                                                {reply} </div>
                                        }
                                        {
                                            reply !== "" && <br />
                                        }
                                        {
                                            reply !== "" && <CFormLabel>Reply By :</CFormLabel>
                                        }
                                        {
                                            reply !== "" && <div style={
                                                {
                                                    color: "#5470af",
                                                    fontSize: "18px",
                                                    fontWeight: 600
                                                }
                                            }
                                                className='border border-1 rounded px-4 py-2'>
                                                {
                                                    replyBy ? replyBy : "Admin"
                                                } </div>
                                        }
                                        <br />
                                    </CCard>
                                </CCol>
                            </CRow>
                        </div>
                    </div>
                )
            } </>
    )
}

import React, { useEffect, useState } from 'react';
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CSpinner
} from '@coreui/react'
import UserCard from './UserCard';
import AppTitle from '../AppTitle';
import Circle from "../../assets/images/circle.svg";
import { getDashboardData, getDashboardUsers } from '../Helper/Index.Api';
import { Link, useNavigate } from 'react-router-dom';
import "./dashbord.css"
import DataTable from 'react-data-table-component';
import CIcon from '@coreui/icons-react';
import strings from '../strings';
const moment = require("moment")


const Dashboard = () => {
  const random = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)
  const [TotalPost, setTotalPost] = useState(0)
  const [TotalAppUser, setTotalAppUser] = useState(0)
  const [TodaysEnquiries, setTodaysEnquiries] = useState(0)
  const [TotalEnquiries, setTotalEnquries] = useState(0)
  const [TotalSystemUser, setTotalSystemUser] = useState(0)
  const [TodaysRegisteredUser, setTodaysRegisteredUser] = useState(0)
  const [isLoading, setisLoading] = useState(false)
  const [users, setUsers] = useState([]);
  const [newusers, setNewUsers] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [communityPages, setCommunityPages] = useState(0);
  const [threads, setTotalthreads] = useState(0);
  const [cppost, setCppost] = useState(0);
  const [post, setPosts] = useState(0);
  const navigate = useNavigate()


  useEffect(() => {
    setisLoading(true)
    // get dashboard data
    getDashboardData()
      .then((res) => {
        setTotalPost(res.TotalPost)
        setTotalAppUser(res.TotalAppUser)
        setTodaysEnquiries(res.TodaysEnquiries)
        setTotalEnquries(res.totalEnquiries)
        setTotalSystemUser(res.TotalSystemUser)
        setTodaysRegisteredUser(res.TodaysRegisteredUSer);
        setCommunityPages(res.TotalCommunitPages);
        setTotalthreads(res.TotalThreadList);
        setCppost(res.TotalCommunityPagePost)
        setPosts(res.TotalPostNormal)
      })
      .finally(() => { setisLoading(false) })
      .catch((err) => console.log(err))

      // dashboard users list
    getDashboardUsers(0)
      .then((res) => {
        setNewUsers(res.data);
        setTotalRows(res.totalusers)
      })
      .catch((error) => {
        console.log(error)
      })

  }, [])

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#5470af",
        color: "white"
      }
    },
    cells: {
      style: {
        borderRight: "1px solid #ddd",
        borderLeft: "1px solid #ddd"
      }
    }
  };




  const columns = [
    {
      name: 'Sr. No.',
      selector: (row, index) => page * 10 + index + 1 - 10,
      width: "70px"
    },
    {
      name: 'Avtar',
      selector: row => (
        <>
          <div className="">
            <img className=" rounded-circle"
              style={
                {
                  width: "40px",
                  height: "40px"
                }
              }
              src={
                row.userAvatar?.cdnlocation ? strings.MEDIAPATH + row.userAvatar?.cdnlocation : "https://th.bing.com/th/id/OIP.Gfp0lwE6h7139625a-r3aAHaHa?w=194&h=194&c=7&r=0&o=5&dpr=1.3&pid=1.7"
              } />
          </div>
        </>
      ),
      width: "75px"
    },
    {
      name: 'Name',
      selector: row => (`${row?.first_Name + " " + row?.last_Name}`)
    },
    {
      name: 'Email',
      selector: row => (`${row?.email}`)
    }, {
      name: 'Phone Number',
      selector: row => (row?.phonenumber ? `${row?.phonenumber}` : "-")
    }, {
      name: 'Registration Date',
      selector: row => (
        <> {
          moment(row?.entryDate).format("DD MMMM YYYY")
        } </>
      ),
      // width: "150px"
    }
  ];


  // row change
  const handlePerRowChange = (page) => {
    if (page === undefined) {
      page = 0;
    }
    var ofs = (page - 1) * 10;
    setisLoading(true);
    getDashboardUsers(ofs).then((res) => {
      setisLoading(false);
      if (res.err === 200) {
        setNewUsers(res.data);
        setTotalRows(res.totalCount);
      } else {
        setisLoading(false);
      }
    }).catch(() => setisLoading(false));
  };

  const paginationComponentOptions = {
    rowsPerPageText: "",
    noRowsPerPage: true
  };

  // pagination for datatable
  const handlePageChange = (page) => {
    if (page === undefined) {
      page = 1;
    }
    setPage(page)
    var ofs = (page - 1) * 10;

    getDashboardUsers(ofs).then((res) => {
      setisLoading(false);
      if (res.err === 200) {
        setNewUsers(res.data);
        setTotalRows(res.totalCount);
      } else {
        setisLoading(false);
      }
    }).catch(() => setisLoading(false));
  };


  return (
    <>
      <AppTitle title={"Dashboard"} />
      <div className='g-1 container-fluid justify-content-center text-align-center px-lg-5 mt-lg-3 px-2 mt-2'>
        <CRow lg={12} className='justify-content-center text-align-center' >
          <CCol xs={12} md={6} lg={3} className='mt-4' >
            <div className=' card pb-2 text-start h-2 position-relative' style={{ height: "150px", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", backgroundColor: "#3ad2bc" }}>
              <div className='card-body text-light h5' style={{ fontFamily: "sans-serif" }} >
                Total Post
                <div className="fs-5 fw-bold text-light mt-4"><p style={{ fontSize: "30px", fontFamily: "sans-serif" }}>{TotalPost}</p></div>
              </div>
              <img src={Circle} style={{ position: "absolute", right: 0, top: 0, width: "", height: "150px" }} alt="circle.svg" />
            </div>
          </CCol >
          <CCol xs={12} md={6} lg={3} className='mt-4' >
            <div className='card pb-2 text-start position-relative' style={{ backgroundColor: "#3599e8", height: "150px", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }} >
              <div className='card-body text-light h5' style={{ fontFamily: "sans-serif" }}>
                Total App User
                <div className="fs-5 fw-bold text-light mt-4"><p style={{ fontSize: "30px" }}>{TotalAppUser}</p></div>
              </div>
              <img src={Circle} style={{ position: "absolute", right: 0, top: 0, width: "", height: "150px" }} alt="circle.svg" />
            </div>
          </CCol>
          <CCol xs={12} md={6} lg={3} className='mt-md-4 mt-4' >
            <div className=' card  pb-2 text-start position-relative' style={{ backgroundColor: "#800080", height: "150px", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
              <div className='card-body text-light h5' style={{ fontFamily: "sans-serif" }}>
                Todays Enquiries
                <div className="fs-5 fw-bold text-light mt-4"><p style={{ fontSize: "30px" }}>{TodaysEnquiries}</p></div>
              </div>
              <img src={Circle} style={{ position: "absolute", right: 0, top: 0, width: "", height: "150px" }} alt="circle.svg" />
            </div>
          </CCol>
          <CCol xs={12} md={6} lg={3} className='mt-md-4 mt-4'>
            <div className=' card pb-2 text-start position-relative' style={{ backgroundColor: "#cc80ff", height: "150px", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }} >
              <div className='card-body text-light h5' style={{ fontFamily: "sans-serif" }}>
                Total Enquiries
                <div className="fs-5 fw-bold text-light mt-4"><p style={{ fontSize: "30px" }}>{TotalEnquiries}</p></div>
              </div>
              <img src={Circle} style={{ position: "absolute", right: 0, top: 0, width: "", height: "150px" }} alt="circle.svg" />
            </div>
          </CCol>
        </CRow>
        <CRow >
          <CCol xs={12} md={6} lg={3} className='mt-4'>
            <div className='card  pb-2 text-start position-relative' style={{ backgroundColor: "#3d30a2", height: "150px", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
              <div className='card-body text-light h5' style={{ fontFamily: "sans-serif" }}>
                Total System Users
                <div className="fs-5 fw-bold text-light mt-4"><p style={{ fontSize: "30px" }}>{TotalSystemUser}</p></div>
              </div>
              <img src={Circle} style={{ position: "absolute", right: 0, top: 0, width: "", height: "150px" }} alt="circle.svg" />
            </div>
          </CCol>
          <CCol xs={12} md={6} lg={3} className='mt-4 '>
            <div className='card  pb-2 text-start position-relative' style={{ height: "150px", backgroundColor: "#ff4b91", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
              <div className='card-body text-light h5' style={{ fontFamily: "sans-serif" }}>
                Todays Registered Users
                <div className="fs-5 fw-bold text-light mt-4"><p style={{ fontSize: "30px" }}>{TodaysRegisteredUser}</p></div>
              </div>
              <img src={Circle} style={{ position: "absolute", right: 0, top: 0, width: "", height: "150px" }} alt="circle.svg" />
            </div>
          </CCol>
          <CCol xs={12} md={6} lg={3} className='mt-md-4 mt-4' >
            <div className=' card  pb-2 text-start position-relative' style={{ backgroundColor: "#fe9696", height: "150px", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
              <div className='card-body text-light h5' style={{ fontFamily: "sans-serif" }}>
                Total Community Pages
                <div className="fs-5 fw-bold text-light mt-4"><p style={{ fontSize: "30px" }}>{communityPages}</p></div>
              </div>
              <img src={Circle} style={{ position: "absolute", right: 0, top: 0, width: "", height: "150px" }} alt="circle.svg" />
            </div>
          </CCol>
          <CCol xs={12} md={6} lg={3} className='mt-md-4 mt-4'>
            <div className=' card pb-2 text-start position-relative' style={{ backgroundColor: "#4169E1", height: "150px", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }} >
              <div className='card-body text-light h5' style={{ fontFamily: "sans-serif" }}>
                Total Threads
                <div className="fs-5 fw-bold text-light mt-4"><p style={{ fontSize: "30px" }}>{threads}</p></div>
              </div>
              <img src={Circle} style={{ position: "absolute", right: 0, top: 0, width: "", height: "150px" }} alt="circle.svg" />
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol xs={12} md={6} lg={3} className='mt-4' >
            <div className='card pb-2 text-start position-relative' style={{ backgroundColor: "#FF0000", height: "150px", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }} >
              <div className='card-body text-light h5' style={{ fontFamily: "sans-serif" }}>
                Posts
                <div className="fs-5 fw-bold text-light mt-4"><p style={{ fontSize: "30px" }}>{post}</p></div>
              </div>
              <img src={Circle} style={{ position: "absolute", right: 0, top: 0, width: "", height: "150px" }} alt="circle.svg" />
            </div>
          </CCol>
          <CCol xs={12} md={6} lg={3} className='mt-4' >
            <div className=' card pb-2 text-start h-2 position-relative' style={{ height: "150px", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", backgroundColor: "#3CB371" }}>
              <div className='card-body text-light h5' style={{ fontFamily: "sans-serif" }} >
                Posts (Community Page)
                <div className="fs-5 fw-bold text-light mt-4"><p style={{ fontSize: "30px", fontFamily: "sans-serif" }}>{cppost}</p></div>
              </div>
              <img src={Circle} style={{ position: "absolute", right: 0, top: 0, width: "", height: "150px" }} alt="circle.svg" />
            </div>
          </CCol >
        </CRow>
      </div>
      <div className='px-lg-5 mt-lg-5 px-2 mt-2'>
        <CCard>
          <CCardHeader>
            <div className='d-flex justify-content-between'>
              <strong>Todays Registered Users</strong>
              <button className='rounded border border-none text-light px-2 py-1' style={{ backgroundColor: "#5470af" }} onClick={() => navigate("/appusers")}>View More</button>
            </div>
          </CCardHeader>
          <CCardBody> {
            isLoading ? <div className="d-flex justify-content-center"><CSpinner style={
              { marginTop: "40px" }
            }
              color="info" /></div> : <DataTable columns={columns}
                data={newusers}
                style={
                  { border: '1px solid black' }
                }
                highlightOnHover
                pagination
                paginationServer
                progressPending={isLoading}
                paginationRowsPerPageOptions={
                  []
                }
                paginationComponentOptions={paginationComponentOptions}
                paginationTotalRows={totalRows}
                customStyles={customStyles}
                onChangePage={handlePageChange} />
          } </CCardBody>
        </CCard>
      </div>
    </>

  )
}

export default Dashboard
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import Dashbord from "./components/Dashbord";
import "./scss/style.scss";
import "./App.css"
import Layout from "./components/Layout";
import './custome.css';
import ForgotPassword from "./components/Login/ForgotPassword";
import ProtectedRoutes from "./context/ProtectedRoute";
import ResetPassword from "./components/Login/ResetPassword";
import Profilepage from "./components/Dashbord/ProfileUpdate";
import ViewUser from "./components/Dashbord/ViewUser";
import EditUser from "./components/Dashbord/EditUser";
import Appusers from "./components/Appusers/Appusers";
import SystemUsers from "./components/System Users/SystemUsers";
import Chatlist from "./components/Handle Messages/Chatlist.jsx";
import Messages from "./components/Handle Messages/Messages";
import ResetPassForUser from "./components/Login/ResetPassForUser";
import Enquiries from "./components/Enquiry/Enquiries";
import Feedback from "./components/Feedback/Feedback";
import ReportedPost from "./components/Reportedpost/ReportedPost";
import ViewReportedPost from "./components/Reportedpost/ViewReportedPost";
import SubscribedUsers from "./components/Subscription/SubscribedPlan";
import SubsribedUserDetails from "./components/Subscription/SubsribedUserDetails";
import { EditSubsrciptionPlans } from "./components/EditSubscriptionPlans/EditSubsrciptionPlans";
import { SubscriptionPlanList } from "./components/EditSubscriptionPlans/SubscriptionPlanList";
import { EnquiriesDetails } from "./components/Enquiry/EnquiriesDetails";
import { FeedbackDetails } from "./components/Feedback/FeedbackDetails";
import MessagesMob from "./components/Handle Messages/MessagesMob";
import AddSubscriptionPlan from "./components/EditSubscriptionPlans/AddSubscriptionPlan";
import Communitypages from "./components/CommunityPages/Communitypages";
import CommunityPageDetailsPage from "./components/CommunityPages/CommunityPageDetails";
import ThreadList from "./components/Threads/ThreadList";
import ThreadDetails from "./components/Threads/ThreadDetails";
import { Posts } from "./components/Posts/Posts.jsx";
import { PostsDetails } from "./components/Posts/PostsDetails.jsx";
import DeletedUsers from "./components/DeletedUsers/deletedUsers.jsx";

function App() {
  return (
    <div>
      {/* <BrowserRouter basename="iabroadadmin"> */}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/user/resetpass/:key?" element={<ResetPassForUser />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:key" element={<ResetPassword />} />

          <Route element={<ProtectedRoutes />}>
            <Route path="/dashboard" element={<Layout><Dashbord /></Layout>} />
            <Route path="/systemusers" element={<Layout><SystemUsers /></Layout>} />
            <Route path="/appusers" element={<Layout><Appusers /></Layout>} />
            <Route path="/viewuser/:id" element={<Layout><ViewUser /></Layout>} />
            <Route path="/edituser/:id" element={<Layout><EditUser /></Layout>} />
            <Route path="/profilepage" element={<Layout><Profilepage /></Layout>} />
            <Route path="/enquiries" element={<Layout><Enquiries /></Layout>} />
            <Route path={"/enquiries/:id"} element={<Layout><EnquiriesDetails /></Layout>} />
            <Route path="/feedback" element={<Layout><Feedback /></Layout>} />
            <Route path={"/feedback/:id"} element={<Layout><FeedbackDetails /></Layout>} />
            <Route path="/reportedposts" element={<Layout><ReportedPost /></Layout>} />
            <Route path="/view/reportedpost/:rpid/:uid" element={<Layout><ViewReportedPost /></Layout>} />
            <Route path="/subscribedusers" element={<Layout><SubscribedUsers /></Layout>} />
            <Route path="/subscribedusers/:id" element={<Layout><SubsribedUserDetails /></Layout>} />
            <Route path="/messages/:chat/:id" element={<Layout><Chatlist /></Layout>} >
              <Route path='/messages/:chat/:id/:cid' element={<Messages />} />
            </Route>
            <Route path="/messagesmob/:chat/:id/:cid" element={<Layout><MessagesMob /></Layout>} />
            <Route path="/subscriptionplans" element={<Layout><SubscriptionPlanList /></Layout>} />
            <Route path="/addsubscriptionplan" element={<Layout><AddSubscriptionPlan /></Layout>} />
            <Route path="/subscriptionplans/:id" element={<Layout><EditSubsrciptionPlans /></Layout>} />
            <Route path="/communitypages" element={<Layout><Communitypages /></Layout>} />
            <Route path="communitypage/:id" element={<Layout><CommunityPageDetailsPage /></Layout>} />
            <Route path="/threads" element={<Layout><ThreadList /></Layout>} />
            <Route path="/threads/:id" element={<Layout><ThreadDetails /></Layout>} />
            <Route path="/posts" element={<Layout><Posts /></Layout>} />
            <Route path="/posts/:id" element={<Layout><PostsDetails /></Layout>} />
            <Route path="/deletedusers" element={<Layout><DeletedUsers /></Layout>} />
          </Route>
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;

import axios from "axios";
import strings from "../../components/strings";

// api base url
const BASE_URL = strings.API_URL;


// for private api
export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Authorization': localStorage.getItem("token"),
  }
});

//for public api
export const axiosPublic = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json"
  }
})
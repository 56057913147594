import { SET } from "./actionTypes";

const initialState = {
    showSidebar: true
}


export const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET:
            return { ...state, showSidebar: payload }
        default:
            return state;
    }
}
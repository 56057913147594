import React, { useEffect, useState } from 'react'
import AppTitle from '../AppTitle'
import { CCard, CCol, CRow, CSpinner } from '@coreui/react'
import { ThreadDetailss } from "../Helper/Index.Api"
import { useParams } from 'react-router-dom';
import ThreadComments from './ThreadComments'
import strings from '../strings';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "./threaddetails.css"
import { FaRegComment } from 'react-icons/fa';
import { AiOutlineLike } from 'react-icons/ai';
import { RiShareForwardLine } from 'react-icons/ri';
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";

const ThreadDetails = () => {
    const [data, setData] = useState({});
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [showmore, setShowmore] = useState(false);



    useEffect(() => {
        setLoading(true)
        // get thread details
        ThreadDetailss(id).then((res) => {
            setData(res.data)
            setLoading(false)
        }).catch(err => console.log(err))
    }, [])

    // carousel settings
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    // rendering media files
    const renderMediaFile = (file, index) => {
        if (file.metadata?.contentType === 'image/*') {
            return (
                <img
                    key={index}
                    src={strings.MEDIAPATH + file.cdnlocation}
                    alt='User Posted Image'
                    className='posted-image carousel_height'
                    style={{ width: '100%', objectFit: "contain", }}
                />
            );
        } else if (file.metadata?.contentType === 'video/*') {
            return (
                <video
                    key={index}
                    src={strings.MEDIAPATH + file.cdnlocation}
                    type={file.type}
                    controls
                    className='posted-video carousel_height'
                    style={{ width: '100%', objectFit: "contain", }}
                ></video>
            );
        }
    };

    return (
        <>
            <AppTitle title={"Thread Details"}
                showbtn={true}
                path={"/threads"} />
            <div className='px-lg-5 mt-lg-5 px-2 mt-2 mb-4'>
                {loading === false && <CRow>
                    <CCol md={12}
                        lg={12}
                        sm={12}
                    >
                        <CCard className='px-lg-4 px-2 py-lg-4 py-2'>
                            <div className='carousel_div_display'>
                                <div style={{ borderRight: "1px solid #80808050" }} className='carousel-width'>
                                    <div className='px-4'>
                                        <div className='d-flex gap-2'>
                                            <img src={data?.createdBy?.userAvatar?.cdnlocation ? strings.MEDIAPATH + data?.createdBy?.userAvatar?.cdnlocation : "https://th.bing.com/th/id/OIP.Gfp0lwE6h7139625a-r3aAHaHa?w=194&h=194&c=7&r=0&o=5&dpr=1.3&pid=1.7"}
                                                style={
                                                    {
                                                        width: "50px",
                                                        height: "50px"
                                                    }
                                                }
                                                className='rounded-circle border border-white border-5'
                                                alt="" />
                                            <div style={{ fontSize: "14px" }}>{data?.createdBy?.first_Name + " " + data?.createdBy?.last_Name}<br />
                                                <span style={{ fontSize: "13px" }}>{data.createdBy?.catchLine !== "" && (data?.createdBy?.catchLine.length > 40 ? data?.createdBy?.catchLine.slice(0, 40) + "..." : data?.createdBy?.catchLine)}</span>
                                    
                                            </div>
                                        </div>
                                        <div style={{ marginBottom: "1rem", marginLeft: "3.125em" }}>
                                            <h6>{data?.title}</h6>
                                        </div>
                                        <div className='mt-1'>
                                            <Slider {...settings}>
                                                {data?.mediaFiles?.map((file, index) => (
                                                    <div key={index}>{renderMediaFile(file, index)}</div>
                                                ))}
                                            </Slider>
                                        </div>
                                        {!showmore && <div className='px-lg-4 px-md-1'>{data?.message?.length > 150 ? data?.message?.slice(0, 150) + "..." : data?.message}</div>}
                                        {showmore && <div className='px-lg-4 px-md-1'>{data?.message}</div>}
                                        <div className='d-flex justify-content-end'>
                                            {!showmore && data?.message?.length > 200 && < button onClick={() => setShowmore(true)} style={{ backgroundColor: "white", border: "none" }} >show more<span><IoMdArrowDropdown /></span></button>}
                                            {showmore && data?.message?.length > 200 && <button onClick={() => setShowmore(false)} style={{ backgroundColor: "white", border: "none" }} >show less<span><IoMdArrowDropup /></span></button>}
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-4 comments-width'>
                                    <div className='px-lg-4 px-md-1'>
                                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }} className='px-lg-4 px-md-1'>
                                            <div className='d-flex gap-2'>
                                                <FaRegComment fontSize={"25px"} /><p style={{ fontSize: "16px", fontWeight: "500" }}> Comments {data?.commentCount}</p>
                                            </div>
                                            <div className='d-flex gap-2'>
                                                <AiOutlineLike fontSize={"25px"} /><p style={{ fontSize: "16px", fontWeight: "500" }}> Likes {data?.likeCount}</p>
                                            </div>
                                            <div className='d-flex gap-2'>
                                                <RiShareForwardLine fontSize={"25px"} /><p style={{ fontSize: "16px", fontWeight: "500" }}> Share {data?.shareCount}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <p style={{ fontSize: "18px", fontWeight: "600" }}>Comments</p>
                                        </div>
                                        <div>
                                            <ThreadComments id={id} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CCard>
                    </CCol>
                </CRow>}
                {
                    loading && <div style={{ display: "flex", justifyContent: "center" }}>
                        <CSpinner color='info' />
                    </div>
                }
            </div>
        </>
    )
}

export default ThreadDetails

import React, { useEffect, useState } from 'react';
import { editUser, viewUser } from '../Helper/Index.Api';
import { useParams } from 'react-router-dom';

import {
    CContainer,
    CRow,
    CCol,
    CCard,
    CCardBody,
    CCardHeader,
    CListGroup,
    CListGroupItem,
    CBadge,
    CForm,
    CFormLabel,
    label,
    CFormInput,
    CButton
} from "@coreui/react";
import strings from '../strings';
import swal from 'sweetalert';

const EditUser = () => {
    let { id } = useParams();
    const [data, setData] = useState();
    const [formData, setFormData] = useState({
        _id: "64632eed491b489ef9f18afa",
        abbreviation: '',
        first_Name: '',
        last_Name: '',
        email: '',
        gender: '',
        phonenumber: '',
        district: '',
        city: '',
        state: '',
        language: [],
        catchLine: ''
    });

    // get user details
    const getUser = () => {
        viewUser({ _id: id }).then((res) => {
            if (res.err === 200) {
                setData(res.data);
                setFormData(res.data);
            }
        });
    }
    useEffect(() => {
        getUser();
    }, []);

    // input fields change
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    // form submit
    const handleFormSubmit = () => {
        editUser(formData).then((res) => {
            if (res.err === 200) {
                swal("Success", res.msg, "success").then((willok) => {
                    if (willok) {
                        getUser();
                    }
                })
            } else {
                swal("Warning", res.msg, "warning");
            }
        });
    };

    return (
        <CContainer>
            <CRow className=''>
                <CCol md="12" >
                    <CCard>
                        <CCardHeader>
                            <h4>Edit User Profile</h4>
                        </CCardHeader>
                        <CCardBody>
                            <CForm>
                                <CRow>
                                    <CCol md="6">
                                        <CListGroup>
                                            <CListGroupItem>
                                                <div className='bg-white'>
                                                    <img
                                                        src={`${strings.ADMINPROFILE}${data?.avtar}`}
                                                        alt="User Avatar"
                                                        className='img-fluid'
                                                    />
                                                </div>
                                                <CFormLabel htmlFor="abbreviation">Abbreviation</CFormLabel>
                                                <CFormInput
                                                    type="text"
                                                    id="abbreviation"
                                                    name="abbreviation"
                                                    value={formData.abbreviation}
                                                    onChange={handleInputChange}
                                                />
                                            </CListGroupItem>
                                            <CListGroupItem>
                                                <CFormLabel htmlFor="first_Name">First Name</CFormLabel>
                                                <CFormInput
                                                    type="text"
                                                    id="first_Name"
                                                    name="first_Name"
                                                    value={formData.first_Name}
                                                    onChange={handleInputChange}
                                                />
                                            </CListGroupItem>
                                            <CListGroupItem>
                                                <CFormLabel htmlFor="last_Name">Last Name</CFormLabel>
                                                <CFormInput
                                                    type="text"
                                                    id="last_Name"
                                                    name="last_Name"
                                                    value={formData.last_Name}
                                                    onChange={handleInputChange}
                                                />
                                            </CListGroupItem>
                                            <CListGroupItem>
                                                <CFormLabel htmlFor="email">Email</CFormLabel>
                                                <CFormInput
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleInputChange}
                                                />
                                            </CListGroupItem>
                                        </CListGroup>
                                    </CCol>
                                    <CCol md="6">
                                        <CListGroup>
                                            <CListGroupItem>
                                                <CFormLabel htmlFor="phonenumber">Phone Number</CFormLabel>
                                                <CFormInput
                                                    type="text"
                                                    id="phonenumber"
                                                    name="phonenumber"
                                                    value={formData.phonenumber}
                                                    onChange={handleInputChange}
                                                />
                                            </CListGroupItem>
                                            <CListGroupItem>
                                                <CFormLabel htmlFor="district">District</CFormLabel>
                                                <CFormInput
                                                    type="text"
                                                    id="district"
                                                    name="district"
                                                    value={formData.district}
                                                    onChange={handleInputChange}
                                                />
                                            </CListGroupItem>
                                            <CListGroupItem>
                                                <CFormLabel htmlFor="city">City</CFormLabel>
                                                <CFormInput
                                                    type="text"
                                                    id="city"
                                                    name="city"
                                                    value={formData.city}
                                                    onChange={handleInputChange}
                                                />
                                            </CListGroupItem>
                                            <CListGroupItem>
                                                <CFormLabel htmlFor="state">State</CFormLabel>
                                                <CFormInput
                                                    type="text"
                                                    id="state"
                                                    name="state"
                                                    value={formData.state}
                                                    onChange={handleInputChange}
                                                />
                                            </CListGroupItem>
                                            <CListGroupItem>
                                                <CFormLabel htmlFor="language">Known Languages</CFormLabel>
                                                <CFormInput
                                                    type="text"
                                                    id="language"
                                                    name="language"
                                                    value={formData?.language}
                                                    onChange={handleInputChange}
                                                />
                                            </CListGroupItem>

                                            <CListGroupItem>
                                                <CFormLabel htmlFor="gender">Gender</CFormLabel>
                                                <CFormInput
                                                    type="text"
                                                    id="gender"
                                                    name="gender"
                                                    value={formData.gender}
                                                    onChange={handleInputChange}
                                                />
                                            </CListGroupItem>
                                        </CListGroup>
                                    </CCol>
                                </CRow>
                                <CButton color="primary" onClick={handleFormSubmit}>
                                    Save Changes
                                </CButton>
                            </CForm>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </CContainer>

    );
};

export default EditUser;
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarToggler,
} from "@coreui/react";
import { AppSidebarNav } from "./AppSidebarNav";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import IALogoWithText from "../assets/images/indian_aboard_text_logo.png"
import IALogo from "../assets/images/indians_aboard_logo.png";
import navigation from "../_nav";

const AppSidebar = () => {
  // const [showSidebar, setShowSidebar] = useState(true)
  const { showSidebar } = useSelector((store) => store.sidebarReducer)

  return (
    <CSidebar
      position="fixed"
      visible={showSidebar}
    >
      <CSidebarBrand className="d-none d-md-flex " style={{ backgroundColor: "white" }} to="/">
        <img src={IALogo} style={{ height: "30px" }} alt="" /> &nbsp;&nbsp;
        <img src={IALogoWithText} style={{ height: "15px" }} alt="" />
      </CSidebarBrand>
      <CSidebarNav style={{backgroundColor:"#5470af"}}>
        <SimpleBar>
          <AppSidebarNav items={navigation} />
        </SimpleBar>
      </CSidebarNav>
    </CSidebar>
  );
};

export default React.memo(AppSidebar);

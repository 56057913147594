import React, {useState, useEffect} from "react";
import {
    CButton,
    CCol,
    CRow,
    CContainer,
    CHeaderDivider,
    CBreadcrumb,
    CBreadcrumbItem
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import imageprofile from "../../Image/userp.png";
// import { useSelector, useDispatch } from "react-redux";
// import {profilePhotoApi} from "../Helper/profilephto"
import {Link, useSubmit} from "react-router-dom";
import swal from "sweetalert";
import {updateProfile} from "../Helper/loginapi";
import strings from "../strings";
import AppTitle from "../AppTitle";


const Profilepage = () => {
    let orimage = localStorage.getItem("profile");
    let path = strings.ADMINPROFILE + orimage;
    let _id = localStorage.getItem("_id");
    let userName = localStorage.getItem("userName")
    let phoneNumber = localStorage.getItem("number")
    let Email = localStorage.getItem("email");

    const [imgErr, setImgErr] = useState();
    const [profilePic, setProfilePic] = useState(path);
    const [name, setName] = useState(userName);
    const [email, setEmail] = useState(Email);
    const [number, setNumber] = useState(phoneNumber);
    const [profileImage, setProfileImage] = useState();
    const [password, setPassword] = useState();

    const [nameErr, setNameErr] = useState();
    const [emailErr, setEmailErr] = useState();
    const [numberErr, setNumErr] = useState();
    const [passErr, setPassErr] = useState();


    // setting images while updating profiles
    const setImage = (e) => {
        const file = e.target.files[0];
        console.log(file ?. type)
        if ((file ?. type ?. slice(6) === "png") || (file ?. type ?. slice(6) === "jpg") || (file ?. type ?. slice(6) === "jpeg")) {
            setImgErr("");
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                if (reader.result != null) {
                    setProfilePic(reader.result.toString());
                }
            };
            setProfileImage(e.target.files[0]);
        } else {
            setImgErr("Please select image of png, jpg format");
        }
    };

    // update profile pictures
    const handleClick = (e) => {
        e.preventDefault();
        let validate = true;

        if (name === undefined || name === null) {
            setNameErr("Please enter Name");
            validate = false;
        }
        if (email === undefined || email === null) {
            setEmailErr("Please enter Email");
            validate = false;
        }
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            setEmailErr("Please enter valid Email");
            validate = false;
        }
        if (number === undefined || number === null) {
            setNumErr("Please enter mobile number");
            validate = false;
        }
        if (!/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(number)) {
            setNumErr("Please enter valid phone number");
            validate = false;
        }

        if (validate) {
            setNameErr("");
            setEmailErr("");
            setNumErr("");
            setPassErr("");
            let formdata = new FormData();
            formdata.append("_id", _id);
            formdata.append("userName", name);
            formdata.append("email", email);
            formdata.append("profile", profileImage);
            formdata.append("phoneNumber", number);
            // formdata.append("password", password);
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };
            updateProfile(formdata, config).then((result) => {
                if (result.err === 200) {
                    swal("Success", result.msg, "success").then((ok) => {
                        if (ok) {
                            localStorage.setItem("userName", result.data.userName);
                            localStorage.setItem("email", result.data.email);
                            localStorage.setItem("phoneNumber", result.data.phoneNumber);
                            localStorage.setItem("profile", result.data.profile);
                            window.location.reload();
                        }
                    })
                }
            })
        }
    }

    return (
        <>
            <CHeaderDivider/>
            <AppTitle title={"Profile"}
                path={"/dashboard"}
                showbtn={true}/> {/* <CContainer fluid>
                <CRow>
                    <CCol xs={12}>
                        <h5 className="main-title">Profile</h5>
                    </CCol>
                    <CCol xs={8}>
                        <CBreadcrumb className="m-0 ms-2"
                            style={
                                {"--cui-breadcrumb-divider": "'>'"}
                        }>
                            <CBreadcrumbItem>
                                <Link to="/dashboard">Home</Link>
                            </CBreadcrumbItem>
                            <CBreadcrumbItem actives>Profile</CBreadcrumbItem>
                        </CBreadcrumb>
                    </CCol>
                    <CCol xs={4}>
                        <div className="text-end">
                            <Link to="/dashboard">
                                <CButton color="warning" size="sm" className="px-4 text-end text-white"
                                    style={
                                        {marginTop: "-52px"}
                                    }
                                    type="button">
                                    Back
                                </CButton>
                            </Link>
                        </div>
                    </CCol>
                </CRow>
            </CContainer> */}
            <br/>
            <div className="container">
                <div className="row">
                    <div className="col-md-5">
                        <form action="" className="card p-4">
                            <div className="mt-4"
                                style={
                                    {
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center"
                                    }
                            }>
                                <img src={strings.MEDIAPATH+localStorage.getItem("profile")}
                                    alt=""
                                    className="rounded-circle mb-4"
                                    style={
                                        {
                                            width: "200px",
                                            height: "200px"
                                        }
                                    }/>
                                <span className="text-danger">
                                    {imgErr}</span>
                                <input type="file" className="form-control"
                                    onChange={setImage}/>

                            </div>

                            <div className="mt-4">
                                <input type="text"
                                    value={name}
                                    placeholder="Enter Name"
                                    className="form-control"
                                    onChange={
                                        (e) => {
                                            setName(e.target.value);
                                            setNameErr("");
                                            setEmailErr("");
                                            setNumErr("");
                                            setPassErr("");
                                        }
                                    }/>
                                <span className="text-danger">
                                    {nameErr}</span>
                            </div>
                            <div className="mt-4">
                                <input type="text"
                                    value={email}
                                    placeholder="Enter Email"
                                    className="form-control"
                                    onChange={
                                        (e) => {
                                            setEmail(e.target.value);
                                            setNameErr("");
                                            setEmailErr("");
                                            setNumErr("");
                                            setPassErr("");
                                        }
                                    }/>
                                <span className="text-danger">
                                    {emailErr}</span>
                            </div>
                            <div className="mt-4">
                                <input type="text"
                                    value={number}
                                    placeholder="Enter Phone Number"
                                    className="form-control"
                                    onChange={
                                        (e) => {
                                            setNumber(e.target.value);
                                            setNameErr("");
                                            setEmailErr("");
                                            setNumErr("");
                                            setPassErr("");
                                        }
                                    }/>
                                <span className="text-danger">
                                    {numberErr}</span>
                            </div>
                            <div className="mt-4 ">
                                <button onClick={handleClick}
                                    style={
                                        {
                                            backgroundColor: "#5470af",
                                            fontSize: "16px"
                                        }
                                    }
                                    className="btn btn-primary w-100">Update profile</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Profilepage;

import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import { messageList } from '../Helper/Index.Api';
import strings from '../strings';
import moment from 'moment';
import { CSpinner } from '@coreui/react';
import VideoImageThumbnail from 'react-video-thumbnail-image';
import { MdAssignment, MdDownloadForOffline } from "react-icons/md";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import "./message.css";
import ReactAudioPlayer from 'react-audio-player';
const Messages = () => {
    let { chat, id, cid } = useParams();
    const [listLoading, setLoading] = useState(false);
    const [messages, setMessages] = useState([]);
    const [imageLoading, setImageLoading] = useState(true);

    const handleImageLoad = () => {
        setImageLoading(false);
    };

    // Function to handle image error
    const handleImageError = () => {
        setImageLoading(false);
    };


    // get all messages
    useEffect(() => {
        let data = {
            search: "",
            chatId: cid,
            sortBy: ""

        }

        setLoading(true);
        messageList(data).then((res) => {
            if (res.err === 200) {
                setLoading(false);
                setMessages(res.data.reverse());
            }
        }).catch((err) => {
            if (err) {
                setLoading(false);
            }
        })
    }, [cid]);


    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "auto" })
    }

    // scrolling to the bottom when its load messages
    useEffect(() => {
        scrollToBottom();
    }, [messages]);


    // showing message content
    function content(msg, content_type) {
        if (content_type === "text/plain" || content_type === "") {
            return msg.content
        } else if (content_type && content_type?.slice(0, 5) === "image") {
            if (msg.content === "") {
                return <div>

                    <img src={
                        msg?.file[0].cdnlocation ? strings.MEDIAPATH + msg?.file[0].cdnlocation : "https://th.bing.com/th/id/OIP.FgSHy4RMgjNNcLv1UqLDdgHaH_?w=160&h=180&c=7&r=0&o=5&dpr=1.3&pid=1.7"
                    }
                        style={
                            {
                                width: "150px",
                                height: "200px",
                                display: imageLoading ? "none" : "block",
                                borderRadius: "10px"
                            }
                        }
                        alt=""
                        onLoad={handleImageLoad}
                        onError={handleImageError} />
                </div>
            } else {
                return <div>
                    <div className='border border-white' style={{ borderRadius: "10px" }}>
                        <img src={
                            msg?.file[0].cdnlocation ? strings.MEDIAPATH + msg?.file[0].cdnlocation : "https://th.bing.com/th/id/OIP.FgSHy4RMgjNNcLv1UqLDdgHaH_?w=160&h=180&c=7&r=0&o=5&dpr=1.3&pid=1.7"
                        }
                            style={
                                { width: "150px", height: "200px", borderRadius: "10px" }
                            }
                            alt=""

                        />
                    </div>
                    <div className='text-start'>
                        <small>{
                            msg.content
                        }</small>
                    </div>

                </div>
            }
        } else if (content_type && content_type?.slice(0, 5) === "video") {
            if (msg.content === "") {
                return <>
                    <div>
                        <video controls width="150" height="200" style={{ borderRadius: "10px" }}>
                            <source src={
                                strings.MEDIAPATH + msg?.file[0].cdnlocation
                            }
                                width="150"
                                height="200"
                                type="video/mp4" />
                            Your browser does not support the video tag.
                        </video> </div>

                </>
            } else {
                return <div className='text-start'>
                    <video controls width="150" height="200" style={{ borderRadius: "10px" }}>
                        <source src={
                            strings.MEDIAPATH + msg?.file[0].cdnlocation
                        }
                            width="150"
                            height="200"
                            type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <br />
                    <small className='text-start'>
                        {
                            msg.content
                        }</small>
                </div>
            }

        } else if (content_type && content_type?.slice(0, 5) === "audio") {
            if (msg.content === "") {
                return <div>
                    <ReactAudioPlayer
                        src={strings.MEDIAPATH + msg?.file[0].cdnlocation}
                        controls
                    />
                </div>
            } else {
                return <div>
                    <ReactAudioPlayer
                        src={strings.MEDIAPATH + msg?.file[0].cdnlocation}
                        controls
                    />
                    <br /> {
                        msg.content
                    } </div>
            }
        }
        else if (content_type && content_type === "group-invitaton") {
            return <div>
                <p style={{ marginBottom: "0px" }}>Group invitation</p>
                <p style={{ fontSize: "16px", fontWeight: 600, marginBottom: "0px" }}>{msg?.invitedGroupId?.chatName}</p>
            </div>
        }
        else {
            return < a href={`${strings.MEDIAPATH + msg.file[0]?.key}`} target="_blank" download style={{ display: "flex", justifyContent: "space-between", textDecoration: "none" }
            }>
                <p><BsFillFileEarmarkPdfFill fontSize={"50px"} color='red' /></p><span style={{ marginTop: "40px" }}><MdDownloadForOffline fontSize={"20px"} color='black' /></span>
            </a >
        }
    }

    function NewDate(date) {
        let currentDate = new Date();
        currentDate.setHours(12, 0, 0, 0);
        if (currentDate > date) {
            return true;
        }
        else {
            return false;
        }

    }

    return (
        <>
            <div className="container d-lg-flex d-none">
                <div className="row px-xl-4 px-1"
                    style={
                        { width: "100%" }
                    }>
                    {
                        listLoading ? (
                            <>
                                <div className="col-12 text-center mt-5">
                                    <CSpinner color="info" />
                                </div>
                            </>
                        ) : (
                            <> {
                                messages?.length > 0 ? (
                                    <> {
                                        messages?.map((msg, index) => {
                                            if (msg?.createdBy?._id !== id) {
                                                return <div className="col-lg-12 text-start d-flex justify-content-start"
                                                    key={index}>
                                                    <div>
                                                        <img src={
                                                            msg?.createdBy?.userAvatar?.cdnlocation !== "" ? strings.MEDIAPATH + msg?.createdBy?.userAvatar?.cdnlocation : "https://th.bing.com/th/id/OIP.Gfp0lwE6h7139625a-r3aAHaHa?w=194&h=194&c=7&r=0&o=5&dpr=1.3&pid=1.7"
                                                        }
                                                            className='rounded-circle me-3 mt-3'
                                                            style={
                                                                {
                                                                    width: "40px",
                                                                    height: "40px"
                                                                }
                                                            }
                                                            alt="image" />
                                                    </div>
                                                    <div className='text-start mt-3'
                                                        style={
                                                            { maxWidth: "500px" }
                                                        }>
                                                        <p style={
                                                            { fontSize: "12px" }
                                                        }>
                                                            {
                                                                msg?.createdBy?.first_Name
                                                            }
                                                            {" "}
                                                            {
                                                                msg?.createdBy?.last_Name
                                                            }</p>
                                                        <div className=' px-2 py-2 rounded-4'
                                                            style={
                                                                {
                                                                    backgroundColor: "rgba(4, 117, 107, 0.1)",
                                                                    color: "#000000",
                                                                    // fontWeight: "bold",
                                                                    wordWrap: "break-word",
                                                                    marginTop: "-15px",
                                                                    fontSize: "14px"
                                                                }
                                                            }>
                                                            {
                                                                msg?.shareContentType === "group-invitation" && content(msg, msg?.shareContentType)
                                                            }
                                                            {
                                                                msg?.shareContentType === "normalmessage" && msg?.shareContentType !== "group-invitation" && content(msg, msg.content_type)
                                                            }
                                                            {
                                                                msg?.shareContentType !== "normalmessage" && msg?.shareContentType !== "group-invitation" && msg.file.length > 0 && content(msg, msg.file[0].metadata.contentType)
                                                            }
                                                            {
                                                                msg?.shareContentType !== "normalmessage" && msg?.shareContentType !== "group-invitation" && msg.file.length <= 0 && content(msg, msg?.content_type)
                                                            }
                                                        </div>
                                                        <small className='me-auto'
                                                            style={
                                                                { fontSize: "10px" }
                                                            }>
                                                            {NewDate(msg?.createdAt) && moment(msg?.createdAt).format("hh:mm A")}
                                                            {!NewDate(msg?.createdAt) && moment(msg?.createdAt).format('D MMM YY  h:mm A')}</small>
                                                    </div>
                                                </div>
                                            } else {
                                                return <div className="col-lg-12 text-end d-flex justify-content-end">
                                                    <div className='text-end mt-3'
                                                        style={
                                                            { maxWidth: "500px" }
                                                        }>
                                                        <div className='py-2 px-2 rounded-4'
                                                            style={
                                                                {
                                                                    color: "#000000",
                                                                    background: "#d1d7db",
                                                                    wordWrap: "break-word",
                                                                    fontSize: "14px",
                                                                    textAlign: "start"
                                                                }
                                                            }>
                                                            {
                                                                msg?.shareContentType === "group-invitation" && content(msg, msg?.content_type)
                                                            }
                                                            {
                                                                msg?.shareContentType === "normalmessage" && msg?.shareContentType !== "group-invitation" && content(msg, msg.content_type)
                                                            }
                                                            {
                                                                msg?.shareContentType !== "normalmessage" && msg?.shareContentType !== "group-invitation" && msg.file.length > 0 && content(msg, msg.file[0].metadata.contentType)
                                                            }
                                                            {
                                                                msg?.shareContentType !== "normalmessage" && msg?.shareContentType !== "group-invitation" && msg.file.length <= 0 && content(msg, msg?.content_type)
                                                            }
                                                        </div>
                                                        <small className='ms-auto'
                                                            style={
                                                                {
                                                                    fontSize: "10px",
                                                                }
                                                            }>
                                                            {NewDate(msg?.createdAt) && moment(msg?.createdAt).format("hh:mm A")}
                                                            {!NewDate(msg?.createdAt) && "today" && moment(msg?.createdAt).format('D MMM YY  h:mm A')}
                                                            &nbsp; &nbsp; &nbsp;
                                                        </small>
                                                    </div>
                                                    <div>
                                                        <img src={
                                                            msg?.createdBy?.userAvatar?.cdnlocation !== "" ? strings.MEDIAPATH + msg?.createdBy?.userAvatar?.cdnlocation : "https://th.bing.com/th/id/OIP.Gfp0lwE6h7139625a-r3aAHaHa?w=194&h=194&c=7&r=0&o=5&dpr=1.3&pid=1.7"
                                                        }
                                                            className='mt-3 ms-3 rounded-circle'
                                                            style={
                                                                {
                                                                    width: "40px",
                                                                    height: "40px"
                                                                }
                                                            }
                                                            alt="" />
                                                    </div>

                                                </div>
                                            }
                                        })
                                    } </>
                                ) : (
                                    <>
                                        <div className='text-center mt-5'>
                                            <h4 className='text-secondary'>No Messages Found</h4>
                                        </div>
                                    </>
                                )
                            } </>
                        )
                    }
                    <div ref={messagesEndRef} />
                </div>
            </div>

        </>

    )
}
export default Messages;

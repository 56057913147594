import React, { useEffect } from 'react';
import CIcon from '@coreui/icons-react';
import {
  cilBellExclamation, cilSettings, cilDescription, cilPeople, cilReportSlash, cilPen
  , cilChatBubble, cilScreenSmartphone, cilShieldAlt, cilSpeedometer, cilUser, cilImage, cilTrash
} from '@coreui/icons';
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react';
import pricingIcon from '../src/Image/pricing.png'


const _nav = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'App Users',
    to: '/appusers',
    icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Enquiries",
    to: "/enquiries",
    icon: <CIcon icon={cilChatBubble} customClassName={"nav-icon"} />
  },
  {
    component: CNavItem,
    name: "Feedback",
    to: "/feedback",
    icon: <CIcon icon={cilDescription} customClassName={"nav-icon"} />
  },
  {
    component: CNavItem,
    name: "Reported Posts",
    to: "/reportedposts",
    icon: <CIcon icon={cilBellExclamation} customClassName={"nav-icon"} />
  },
  {
    component: CNavItem,
    name: "Community Pages",
    to: "/communitypages",
    icon: <CIcon icon={cilPeople} customClassName="nav-icon" />
  },
  {
    component: CNavItem,
    name: "Threads",
    to: "/threads",
    icon: <CIcon icon={cilPen} customClassName="nav-icon" />
  },
  {
    component: CNavItem,
    name: "Posts",
    to: "/posts",
    icon: <CIcon icon={cilImage} customClassName="nav-icon" />
  },
  {
    component: CNavItem,
    name: "Subscribed Users",
    to: "/subscribedusers",
    icon: <CIcon icon={cilShieldAlt} customClassName={"nav-icon"} />
  },
  {
    component: CNavItem,
    name: "Deleted Users",
    to: "/deletedusers",
    icon: <CIcon icon={cilTrash} customClassName={"nav-icon"} />
  },
  {
    component: CNavItem,
    name: "Plan Configuration",
    to: "/subscriptionplans",
    icon: <CIcon icon={cilSettings} customClassName={"nav-icon"} />
  }
]
export default _nav;
import { CCard, CCol, CRow, CSpinner } from '@coreui/react';
import React from 'react'
import strings from '../strings';

const Followers = ({ followersData }) => {

    return (
        <>
            {
                followersData ? (<>
                    <CRow className='mt-3 mb-3'>
                        {followersData?.map((data) => {
                            return <CCol lg={3}>
                                <CCard style={{ padding: "10px", borderRadius: "10px", marginBottom: "20px" }}>
                                    <div className='d-flex px-2'>
                                        <div className="user-profile">
                                            <img
                                                src={data?.userId?.userAvatar?.cdnlocation !== "" ? strings.MEDIAPATH + data?.userId?.userAvatar?.cdnlocation : "https://th.bing.com/th/id/OIP.Gfp0lwE6h7139625a-r3aAHaHa?w=194&h=194&c=7&r=0&o=5&dpr=1.3&pid=1.7"}
                                                alt="User Avatar"
                                                className="profile-photo"
                                                style={{ width: "100px", height: "100px" }}
                                            />
                                        </div>
                                    </div>
                                    <div className="user-details mt-1 gap-1 px-2">
                                        <div className="user-name">{`${data.userId.first_Name} ${data.userId.last_Name}`}</div>
                                        <small className='text-secondary'>
                                            <span className=''><strong className=''>{data.userId.followers}</strong>&nbsp;Followers </span>{" "}
                                            <span className=''><strong className=''>{data.userId.following}</strong>&nbsp;Followings</span>
                                        </small>
                                        <small className='text-secondary'>
                                            <i class="fa fa-envelope" aria-hidden="true"></i>&nbsp;&nbsp;
                                            <strong>{data.userId.email ? data.userId.email : "-"}</strong>
                                        </small>
                                        <small className='text-secondary'>
                                            <i class="fa fa-phone" aria-hidden="true"></i>&nbsp;&nbsp;
                                            <strong>{data.userId.phonenumber}</strong>
                                        </small>
                                        <small className='text-secondary'>
                                            <i class="fa fa-university" aria-hidden="true"></i>&nbsp;&nbsp;
                                            <strong>{data?.userId?.universityORcompany ? data?.userId?.universityORcompany?.length > 20 ? data?.userId?.universityORcompany?.slice(0, 20) + "..." : data?.userId?.universityORcompany : "-"}</strong>
                                        </small>
                                        <small className='text-secondary'>
                                            <i class="fa fa-map-marker" aria-hidden="true"></i>&nbsp;&nbsp;
                                            <strong>{data?.userId?.state ? data?.userId?.state : "-"}{" "}</strong>
                                        </small>
                                        <small className='text-secondary'>
                                            <i class="fa fa-globe" aria-hidden="true"></i>&nbsp;&nbsp;
                                            <strong>{data.userId.country ? data.userId.country : "-"}</strong>
                                        </small>
                                    </div>
                                </CCard>
                            </CCol>
                        })}

                    </CRow>

                </>) : (<>
                    <CSpinner color='info' />
                </>)
            }

        </>

    )
}

export default Followers;   
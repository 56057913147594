import React, { useEffect, useState } from "react";
// import "./login.css";
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import swal from "sweetalert";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CFormLabel,
  CFormFeedback,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilLockLocked, cilUser } from "@coreui/icons";
import { loginApi } from "../Helper/loginapi";
import logo from "../../Image/logo.png";
import { LoginApi } from "../../redux/authReducer/action";

const Login = () => {
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginemail, setloginemail] = useState("");
  const [password, setpassword] = useState("");
  const [isremember, setisRemember] = useState(false);





  const navigate = useNavigate();


// login functionality

  const handleSubmit = (event) => {
    event.preventDefault();
    // setLoading(true);
    if(loginemail.length===0 || password.length===0){
      swal("Login","Please Enter Your Email Id and Password.")
      return
    }
    let payloadData = {
      email: loginemail,
      password: password,
    };
    // ///login api using redux and thunk
    dispatch(LoginApi(payloadData))
      .then((res) => {
        if (res.status === 200) {
          window.location.replace("/dashboard");
        }
        else {
          swal("Login", res.msg);
        }
      })
      .catch((err) => console.log("err:", err))
  };

  // forgot password 
  const handleForgotPassWord = (e) => {
    e.preventDefault();
    console.log("at forgot password")
    navigate("/forgot-password");
  };

  useEffect(() => {
    setloginemail(window.localStorage.getItem("Email"));
    setisRemember(window.localStorage.getItem("isrememberMe"));
    setpassword(window.localStorage.getItem("Password"));
  }, []);

  const handleRememberMe = () => {
    console.log("remember me here")
  }

  const funrememberme = () => {
    setisRemember(!isremember);
    localStorage.setItem("isrememberMe", !isremember)
    if (!isremember) {
      localStorage.setItem("Email", loginemail)
      localStorage.setItem("Password", password)
    } else {
      localStorage.setItem("Email", "")
      localStorage.setItem("Password", "")
    }
  };

  return (
    <div className="min-vh-100 d-flex flex-row align-items-center loginBG">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol xs={12} md={6} sm={4} lg={4}>
            <CCardGroup>
              <CCard className="p-1 bg-dark text-white rounded-4" style={{ boxShadow: "rgba(0, 0, 0, 0.3) 10px 10px 10px", minWidth: "320px" }}>
                <CCardBody>
                  <CForm
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <div className="row rounded-4 mb-2 ">
                      <div className="col-3"></div>
                      <div className="col-6 text-center  rounded-5   ">
                        &nbsp;{" "}
                        <h4 className="text-white">Indian's Abroad</h4>
                      </div>
                      <div className="col-3"></div>
                    </div>
                    <h4>Login</h4>
                    <p className=" text-white">
                      Sign In to your account
                    </p>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        placeholder="Email"
                        type="email"
                        name="email"
                        value={loginemail}
                        onChange={(e) => {
                          setloginemail(e.target.value);
                        }}
                        required
                      />
                      <CFormFeedback invalid>Please enter email.</CFormFeedback>
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        placeholder="Password"
                        name="password"
                        value={password}
                        onChange={(e) => {
                          setpassword(e.target.value);
                        }}
                        autoComplete="current-password"
                        required
                      />
                      <CFormFeedback invalid>
                        Please enter password.
                      </CFormFeedback>
                    </CInputGroup>
                    <CCol xs={6} className="text-right">
                      <CButton
                        color="link"
                        className="px-0 text-white"
                        onClick={(e) => handleForgotPassWord(e)}
                      >
                        Forgot Password?
                      </CButton>
                    </CCol>

                    <br />
                    <CRow>
                      <CCol xs={12}>
                        <CButton
                          color="primary"
                          className="px-4 w-100"
                          type="submit"
                          disabled={loading}
                        >
                          {loading ? "Wait.." : "Login"}
                        </CButton>
                      </CCol>

                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};
export default Login;
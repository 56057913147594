
import reactSelect from "react-select";
import { axiosPrivate } from "./axios";
import { Title } from "chart.js";

// get all app users list
export const getUsers = async (ofs, userId, email, phonenumber, gender, country) => {
    let res = await axiosPrivate.get(`/api/admindash/getusers?skip=${ofs}&userId=${userId}&email=${email}&phonenumber=${phonenumber}&gender=${gender}&country=${country}`);
    return res.data;
}

// deleting user by admin
export const deleteUser = async (_id, deletedBy, reason) => {
    let res = await axiosPrivate.post("/api/user/delete/account", { userId: _id, deletedBy, reason });
    return res.data;
}

// get all user details
export const viewUser = async (_id) => {
    let res = await axiosPrivate.post("/api/admindash/getuser", _id);
    return res.data;
}

// editing user
export const editUser = async (data) => {
    let res = await axiosPrivate.post("/api/admindash/updateuser", data);
    return res.data;
}

// get single user posts
export const singleuserposts = async (createdBy) => {
    let res = await axiosPrivate.post("/api/post/getallposts", createdBy);
    return res.data;
}

// deleting users posts
export const deleteuserPost = async (data) => {
    let res = await axiosPrivate.post("/api/post/deletepost", data);
    return res.data;
}


// get all system users
export const getSystemUsers = async () => {
    let res = await axiosPrivate.get("/api/admin/getsystemusers");
    return res.data;
}

// get user chats
export const getChatsOfUser = async (data, chat) => {
    let res = await axiosPrivate.post(`/api/chat/list/${chat}`, data);
    return res.data;
}

// all messages of perticular user without pagination
export const messageList = async (data) => {
    let res = await axiosPrivate.post("/api/admin/chat/list/messages", data);
    return res.data;
}

// get followres list
export const followersList = async (userId) => {
    let res = await axiosPrivate.post("/api/followunfollow/followerlist", userId);
    return res.data;
}

// get following list
export const followingList = async (userId) => {
    let res = await axiosPrivate.post("/api/followunfollow/followinglist", userId);
    return res.data;
}

// user forgot password api
export const userForgotPass = async (key, passCode) => {
    let res = await axiosPrivate.post(`/api/user/resetpass?key=${key}`, passCode);
    return res.data;
}

// checking token validity
export const isTokenExpired = async (key) => {
    let res = await axiosPrivate.get(`/api/user/chktknexp?key=${key}`);
    return res.data;
}

// get all enquiries
export const getAllEnquiries = async (ofs, userId, email) => {
    let res = await axiosPrivate.post(`/api/enquiryform/get/all?skip=${ofs}&userId=${userId}&email=${email}`);
    return res.data;
}

// get deleted users
export const getAllDeletedUsers = async (ofs, userId, email) => {
    let res = await axiosPrivate.get(`/api/deleteteduser/users?skip=${ofs}&userId=${userId}&email=${email}`);
    return res.data;
}

// get all feedbacks
export const getAllFeedbacks = async (ofs, userId, email) => {
    let res = await axiosPrivate.post(`/api/feedbackform/get/all?skip=${ofs}&userId=${userId}&email=${email}`);
    return res.data;
}

// get unique countries
export const getUniqueCountries = async () => {
    let res = await axiosPrivate.get(`/api/admindash/getcountries`);
    return res.data;
}

// get admin users
export const getUsersAdmin = async () => {
    let res = await axiosPrivate.get(`/api/admindash/userlist`);
    return res.data;
}

// get reported posts
export const getReportedPosts = async (ofs, userId, post, fromDate, toDate) => {
    let res = await axiosPrivate.post(`/api/report/getallreportedposts?skip=${ofs}&userId=${userId}&post=${post}&fromDate=${fromDate}&toDate=${toDate}`);
    return res.data;
}

// get single post
export const getSinglePost = async (data) => {
    let res = await axiosPrivate.post(`/api/report/sinlgeReportpost`, data);
    return res.data;
}

// get subscription list
export const getSubList = async (ofs, userId) => {
    let res = await axiosPrivate.post(`/api/subscription/getlist?skip=${ofs}&userId=${userId}`);
    return res?.data;
}


// admin dashboard data 
export const getDashboardData = async () => {
    let res = await axiosPrivate.get(`/api/dashboard/getdashboarddata`);
    return res.data;
}

//admin dashboard users
export const getDashboardUsers = async (ofs) => {
    let res = await axiosPrivate.get(`/api/dashboard/dashusers?skip=${ofs}`)
    return res.data;
}


//subscription plans to shown

export const getSubsrciptionPlans = async () => {
    let res = await axiosPrivate.get(`/api/subscriptionplan/getsubscriptionplans`)
    console.log(res.data)
    return res.data
}

//gettting single subscription plan

export const getSingleSubscriptionPlan = async (id) => {
    let res = await axiosPrivate.get(`/api/subscriptionplan/getsubscriptionplans/${id}`)
    return res.data.data
}

//updating subscription plan

export const updateSubscriptionPlan = async (payload) => {
    let res = await axiosPrivate.post(`/api/subscriptionplan/update`, payload)
    return res.data
}

// create new subscription plan
export const CreateSubscriptionPlan = async (payload) => {
    let res = await axiosPrivate.post(`/api/subscriptionplan/create`, payload)
    return res.data
}

// single enquiry details
export const getSingleEnquiryDetails = async (id) => {
    let res = await axiosPrivate.get(`api/enquiryform/get/${id}`)
    console.log(res.data)
    return res.data
}

//reply to the enquiry

export const updateEnquiry = async (payload) => {
    let res = await axiosPrivate.patch(`/api/enquiryform/update`, payload)
    return res.data;
};

// update feedback
export const updateFeedback = async (payload) => {
    let res = await axiosPrivate.patch(`/api/feedbackform/update`, payload)
    return res.data;
};

// get feedback details
export const getFeedbackDetails = async (id) => {
    let res = await axiosPrivate.get(`api/feedbackform/get/${id}`)
    console.log(res.data)
    return res.data
}

// get single subscription plan
export const GetSingleSubscriptionPlan = async (id) => {
    let res = await axiosPrivate.get(`api/subscription/plan/${id}`)
    return res.data;
}

//community pages list
export const GetCommunityList = async (page, searchname, title) => {
    let res = await axiosPrivate.post(`api/admin/cp/cplist?page=${page}&id=${searchname}&title=${title}`)
    return res.data;
}

//community page details
export const CommunityPageDetails = async (id) => {
    let res = await axiosPrivate.get(`api/cp/details/${id}`);
    return res.data;
}

// get community page details
export const GetCommunityPagePosts = async (id) => {
    let res = await axiosPrivate.get(`api/admin/cp/cpposts/${id}`);
    return res.data;
}

// connected indians list
export const ConnectedIndians = async (cpId, page) => {
    let res = await axiosPrivate.get(`api/admin/cp/cpfollowers?page=${page}&id=${cpId}`);
    return res.data;
}

// community page users
export const communityPageUsers = async () => {
    let res = await axiosPrivate.get(`api/admin/cp/cpusers`);
    return res.data;
}

// thread users
export const ThreadUsers = async () => {
    let res = await axiosPrivate.get(`/api/thread/threadusers`);
    return res.data;
}

// post users
export const PostsUsers = async () => {
    let res = await axiosPrivate.get(`/api/adminpost/postusers`);
    return res.data;
}

// admin posts
export const adminPosts = async (ofs, title, userId) => {
    let res = await axiosPrivate.post(`api/adminpost/posts?skip=${ofs}&title=${title}&userId=${userId}`);
    console.log("response Posts:", res.data)
    return res.data;
}

// subscription users
export const SubscriptionUsers = async () => {
    let res = await axiosPrivate.get(`/api/subscription/subscriptionusers`);
    return res.data;
}

// enquiry users
export const EnquiryUsers = async () => {
    let res = await axiosPrivate.get(`/api/enquiryform/enquiryusers`);
    return res.data;
}

// deleted users list
export const DeletedUsers1 = async () => {
    let res = await axiosPrivate.get(`/api/deleteteduser/usersdeleted`);
    return res.data;
}

// feedback users
export const FeedbackUsers = async () => {
    let res = await axiosPrivate.get(`/api/feedbackform/feedbackusers`);
    return res.data;
}

// report post users
export const ReportPostUsers = async () => {
    let res = await axiosPrivate.get(`/api/report/reportedpost/users`);
    return res.data;
}

// thread list 
export const ThreadsList = async (page, country, id, title) => {
    let res = await axiosPrivate.get(`api/thread/threadlist?title=${title}&countryId=${country}&userId=${id}&page=${page}`);
    return res.data;
}

// country list
export const CountryList = async () => {
    let res = await axiosPrivate.get(`api/countries/getlist`);
    return res.data;
}

// thread details
export const ThreadDetailss = async (id) => {
    let res = await axiosPrivate.get(`api/thread/threaddetails/${id}`);
    return res.data;
}

//post details
export const PostDetailss = async (id) => {
    let res = await axiosPrivate.get(`api/adminpost/postdetails/${id}`);
    return res.data;
}

// thread comments
export const ThreadCommentsAdmin = async (id) => {
    let res = await axiosPrivate.get(`api/thread/list/${id}`);
    return res.data;
}

// post comments
export const PostCommentsAdmin = async (id) => {
    let res = await axiosPrivate.post(`api/adminpost/postcomment/${id}`);
    return res.data;
}

// thread comment reply
export const ThreadCommentReply = async (id) => {
    let res = await axiosPrivate.get(`api/thread/comment/reply/list/${id}`);
    return res.data;
}

// post comment reply
export const PostCommentReply = async (id) => {
    let res = await axiosPrivate.post(`api/adminpost/postreply/${id}`);
    return res.data;
}